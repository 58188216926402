import React from 'react'
import '@scss/reset.global.scss'
import '@scss/styles.global.scss'
import { ThemeProvider } from '@context/ThemeContext'
import type { GatsbyBrowser } from 'gatsby'
import { StripeProvider } from '@context/StripeContext'

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => (
  <ThemeProvider>
    <StripeProvider>{element}</StripeProvider>
  </ThemeProvider>
)
