import { useLocalStorage } from '@mantine/hooks'
import React, {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode
} from 'react'
import { useLocation } from '@reach/router'

interface StripeContextProps {
  hostCount: number
  setHostCount: (count: number) => void
  term: 'month' | 'year'
  setTerm: (term: 'month' | 'year') => void
  stripePriceId?: string
  setStripePriceId: (
    val:
      | string
      | ((prevState: string | undefined) => string | undefined)
      | undefined
  ) => void
  webstoreHostLimit: number
}

const StripeContext = createContext<StripeContextProps>({
  hostCount: 10,
  setHostCount: () => {},
  term: 'year',
  setTerm: () => {},
  webstoreHostLimit: 50,
  stripePriceId: undefined,
  setStripePriceId: () => {}
})

export const StripeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [hostCount, setHostCountState] = useState<number>(10)
  const [term, setTerm] = useState<'month' | 'year'>('year')
  const [stripePriceId, setStripePriceId] = useLocalStorage<string | undefined>(
    {
      key: 'stripePriceId',
      defaultValue: undefined
    }
  )

  const setHostCount = (count: number) => {
    if (count < 10) {
      setHostCountState(10)
    } else {
      setHostCountState(count)
    }
  }

  return (
    <div>
      <StripeContext.Provider
        value={{
          hostCount,
          setHostCount,
          term,
          setTerm,
          webstoreHostLimit: 50,
          stripePriceId,
          setStripePriceId
        }}
      >
        {children}
      </StripeContext.Provider>
    </div>
  )
}

export const useStripe = () => {
  return useContext(StripeContext)
}
